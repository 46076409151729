body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#sidebar {
  left: 0;
  background: #003B6A;
  height: 100vh;
  top: 0;
  max-width: 25em;
  min-width: 25em;
  z-index: 1;
  position: fixed;
}

#content {
  margin-left: 30em;
}

.sidebar-logo {
  width: 70%;
  margin: auto;
  display: flex;
  padding-top: 1em;
}

li {
  list-style: none;
  padding: 1em 0;
}

a {
  color: white;
  text-decoration: none;
  font-weight: bold;
  width: 100%;
}

.upload-file {
  cursor: pointer;
}

.form {
  padding: 2% 15%;
}

.input-fields {
  width: 100%;
}

.attach-document {
  padding: 1em 0em;
  /* margin-left: -8px; */
}

.checklist, .upload-status {
  margin-top: 1em;
}

.status {
  font-size: larger;
}

.invalid-feedback {
  color: #f77800;
}